import select2 from "select2";

function multipleKeywordsMatcher(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === "") {
    return data;
  }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === "undefined") {
    return null;
  }

  var terms = params.term.split(" ");
  for (var i = 0; i < terms.length; i++) {
    var tester = new RegExp(terms[i], "i");
    if (tester.test(data.text) == false) {
      return null;
    }
  }
  var modifiedData = $.extend({}, data, true);

  return modifiedData;
}

function initSelect2() {
  $(".searchSelect2").select2({
    placeholder: "",
    matcher: multipleKeywordsMatcher,
    sortResults: function (results) {
      return results;
    },
  });

  $(".searchTagSelect2").select2({
    tags: true,
    placeholder: "",
    matcher: multipleKeywordsMatcher,
    sortResults: function (results) {
      return results;
    },
  });

  $(".locationSelect2").select2({
    placeholder: "Select a location or type your address.",
    tags: true,
    matcher: multipleKeywordsMatcher,
  });
}

function createSelect2({
  $element,
  tags = false,
  placeholder = "",
  matcher = null,
}) {
  return $element.select2({
    placeholder: placeholder,
    tags: tags,
    matcher: matcher || multipleKeywordsMatcher,
  });
}

function createSelect2Ajax({
  $element,
  url,
  parseParams = null,
  minimumInputLength = 3,
  tags = false,
  placeholder = "",
  matcher = null,
}) {
  return $element.select2({
    minimumInputLength: minimumInputLength,
    ajax: {
      url: url,
      delay: 100,
      data: function (params) {
        params.query = params.term;

        if (parseParams) {
          return parseParams(params);
        }

        return params;
      },
    },
    placeholder: placeholder,
    tags: tags,
    matcher: matcher || multipleKeywordsMatcher,
  });
}

function setSelect2Option({ $element, value }) {
  if ($element.find(`option[value='${value}']`).length) {
    $element.val(value).trigger("change");
  } else {
    // Create a DOM Option and pre-select by default
    var newOption = new Option(value, value, true, true);
    // Append it to the select
    $element.append(newOption).trigger("change");
  }
}

function clearSelect2Option({ $element }) {
  $element.val(null).trigger("change");
}

export {
  initSelect2,
  createSelect2,
  setSelect2Option,
  createSelect2Ajax,
  clearSelect2Option,
};
