import "bootstrap";
import "bootstrap-select";
import "jquery-ui-bundle";
import { snakeCase } from "lodash";

import { initDatePicker, createDatePicker } from "../components/datepicker";
import {
  initSelect2,
  createSelect2,
  createSelect2Ajax,
  setSelect2Option,
  clearSelect2Option,
} from "../components/select2";
import { initDashboardNavbar } from "../components/dashboard_navbar";
import { initMagicScroll } from "../components/magic_scrolls";

import { initTerms } from "../pages/terms";
import { initPayment } from "../pages/payment";

import { initDashboard } from "../pages/dashboard";
import { initDarkFilter } from "../components/dark_filter";

initDatePicker();
initSelect2();
initTerms();
initPayment();

initMagicScroll();

initDashboardNavbar();
initDashboard();
initDarkFilter();

const NowLoading = {
  prepare: () => {
    $("#now-loading").remove();
    $("body")
      .append(`<div id="now-loading" style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 10000; display: flex; align-items: center;">
      <div style="width: 100%; height: 100%; background-color: black; opacity: 0.5; position: relative;"></div>
      <div style="position: absolute; width: 100%; display: block; color: white; text-align: center;">Now Loading...</div>
    </div>`);
    $("#now-loading").hide();
  },
  show: () => {
    $("#now-loading").show();
  },
  hide: () => {
    $("#now-loading").hide();
  },
};
NowLoading.prepare();

const refreshElement = (element, beforeFadeIn) => {
  $(element).fadeOut(() => {
    beforeFadeIn();
    $(element).fadeIn();
  });
};

const detectFileSize = async (url) => {
  const response = await fetch(url);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], "image.jpg", { type: blob.type });

  return file.size;
};

const renderFileSizeHints = (url, hints) => {
  detectFileSize(url)
    .then((size) => {
      const sizeInKB = size / 1024;

      hints.textContent = "";
      window.PotalUtil.refreshElement(hints, () => {
        hints.textContent = `Image size: ~${sizeInKB.toFixed(0)} KB`;

        hints.className =
          sizeInKB == 0 || sizeInKB > 1024 ? "text-danger" : "text-muted";
      });
    })
    .catch((error) => {
      console.error(error);
      hints.textContent = "";
      window.PotalUtil.refreshElement(hints, () => {
        hints.textContent = `Failed to detect the image size.`;
        hints.className = "text-danger";
      });
    });
};

window.$ = $;
window.PotalUtil = {
  createSelect2,
  createSelect2Ajax,
  createDatePicker,
  setSelect2Option,
  clearSelect2Option,
  string: {
    snakeCase,
  },
  NowLoading,
  refreshElement,
  detectFileSize,
  renderFileSizeHints,
};
